import { configureStore } from '@reduxjs/toolkit'
import sesionReducer from '../feactures/SesionSlice'
import { usuarioAPI } from '../services/usuario'
import { rolAPI } from '../services/rol'
import { multimediaAPI } from '../services/multimedia'
import { doctorAPI } from '../services/doctor'
import { pacienteAPI } from '../services/paciente'
import { farmaciaAPI } from '../services/farmacia'
import { medicinaAPI } from '../services/medicina'
import { recetaAPI } from '../services/receta'

export const store = configureStore({
    reducer: {
        sesionSlice: sesionReducer,
        [usuarioAPI.reducerPath]: usuarioAPI.reducer,
        [rolAPI.reducerPath]: rolAPI.reducer,
        [multimediaAPI.reducerPath]: multimediaAPI.reducer,
        [doctorAPI.reducerPath]: doctorAPI.reducer,
        [pacienteAPI.reducerPath]: pacienteAPI.reducer,
        [farmaciaAPI.reducerPath]: farmaciaAPI.reducer,
        [medicinaAPI.reducerPath]: medicinaAPI.reducer,
        [recetaAPI.reducerPath]: recetaAPI.reducer,

    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({serializableCheck: false}),
        usuarioAPI.middleware,
        rolAPI.middleware,
        multimediaAPI.middleware,
        doctorAPI.middleware,
        pacienteAPI.middleware,
        farmaciaAPI.middleware,
        medicinaAPI.middleware,
        recetaAPI.middleware,
    ],
});