import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_FARMACIA = 'EurekaBsSucursale'

export const farmaciaAPI = createApi({
    reducerPath: 'farmaciaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Farmacias'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerFarmacias: builder.query({
            /**
             * 
             * @param {{ estatus: string }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_FARMACIA)
                body = data?.estatus ? body.where('estatus', data.estatus) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
        }),
    }),
})


export const { 
    useObtenerFarmaciasQuery,
} = farmaciaAPI