import { Input } from 'eureka-design'
import { capitalize } from '../../js/util'
import Contenido from './Contenido/Contenido'
import './ListaArchivo.css'
import ButtonAgregar from './ButtonAgregar/ButtonAgregar'
import CardArchivo from './CardArchivo/CardArchivo'

const ListaArchivo = ({
    nombre = 'archivo',
    tipoIcono = 'fa-solid',
    icono = 'fa-user', 
    colorIcono = '#CE54EB', 
    titulo = '',
    agregarInput = false,
    agregarBtn = true,
    configInput = {},
    archivos = [],
    extencionesPermitidas = [],
    handlerInput = () => {},
    handlerQuitar = () => {},
    handlerCambiar = () => {},
    handlerDescargar = () => {},
    permitirEliminar = true
}) => {
    return (
        <div className="lista-archivos">
            <div className="lista-archivos__contenido-titulo">
                <div className="lista-archivos__contenido-titulo__icono" style={{ background: colorIcono }}>
                    <i className={`${tipoIcono} ${icono}`} style={{ color: colorIcono }}></i>
                </div>
                <p className="lista-archivos__contenido-titulo__titulo">{capitalize(titulo, 'Sin titulo')}</p>
            </div>

            {
                agregarInput ? 
                    <Input 
                        type="text"
                        placeholder={configInput?.placeholder}
                        nombre={configInput?.nombre}
                        value={configInput?.value}
                        changeFunction={handlerInput}
                    /> : 
                    null
            }

            <Contenido>
                {
                    archivos.map((archivo, indice) => {
                        return (
                            <CardArchivo 
                                key={indice}
                                titulo={archivo.carpeta}
                                nombre={archivo?.archivo ?? archivo?.nombre}
                                onDownload={() => { handlerDescargar(archivo) } }
                                onDelete={() => { handlerQuitar(archivo) }}
                                permitirEliminar={permitirEliminar}
                            />
                        )
                    })
                }
                {
                    agregarBtn ? 
                        <ButtonAgregar 
                            nombre={nombre}
                            allowedExtensions={extencionesPermitidas}
                            onChange={handlerCambiar}
                        /> : null
                }
            </Contenido>
        </div>
    )
}

export default ListaArchivo