import { Card, Button, Input } from 'eureka-design'
import { useEffect, useState } from 'react'
import { MESES } from '../../js/util'

const styleDisplayFlexSpace = {
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
}

const styleDisplayFlex = {
    display: 'flex', 
    justifyContent: 'flex-start', 
    alignItems: 'center',
    gap: '18px'
}

const DetallesReceta = ({ titulo, receta, UpTittle }) => {
    const [fecha] = useState(receta?.fecha ? new Date(receta.fecha) : null)

    useEffect(() => {
        UpTittle(titulo)
    })

    return (
        <>
            <Card>
                <div style={styleDisplayFlexSpace}>
                    <div style={styleDisplayFlex}>
                        <p><b>Fecha:</b> {fecha ? `${fecha.getDate()} ${MESES[fecha.getMonth()]} ${fecha.getFullYear()}` : 'Fecha no válida'}</p>
                        <p><b>Paciente:</b> {receta?.nombre ?? 'Sin paciente'}</p>
                    </div>
                    <div style={styleDisplayFlex}>
                        <Button 
                            iconLeft="fa-print" 
                            texto="Imprimir"
                        />
                        <Button 
                            iconLeft="fa-file-arrow-down" 
                            texto="Descargar" 
                        />
                    </div>
                </div>

                <Input
                    type="textarea" 
                    placeholder="Diagnóstico"
                    value={'Infección de garganta nivel II'}
                    disabled={true}
                />
            </Card>
            <br />
            <Card>
                <div style={styleDisplayFlexSpace}>
                    <p><b>3 Medicamentos recetados</b></p>
                    <p><b>TOTAL: $450.00</b></p>
                </div>
                <p>Paracetamol 20gr - Tomar 1 pastilla cada 8 horas durante 3 días</p>
                <div>

                </div>
                <p>Paracetamol 20gr - Tomar 1 pastilla cada 8 horas durante 3 días</p>
                <div>
                    
                </div>
            </Card>
        </>
    )
}

export default DetallesReceta