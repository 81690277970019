import { useState } from 'react'
import CheckBox from '../CheckBox/CheckBox'
import './Categorias.css'
import { Card } from 'eureka-design'

const Categorias = ({
    titulo = 'Categorías',
    categorias = [],
    onClick = () => { },
}) => {
    const [categoriasSeleccionadas, fijaCategoriasSeleccionadas] = useState([])

    return (
        <Card>
            <div className="seccion-categorias">
                <h1 className="seccion-categorias__titulo">{titulo}</h1>
                {
                    categorias.map((c, i) => {
                        let main_seleccionado = categoriasSeleccionadas.find((categoria) => categoria.id === c.id)?.estatus

                        return (
                            <div
                                key={i}
                                className="seccion-categorias__contenido"
                            >
                                <CheckBox
                                    seleccionado={main_seleccionado}
                                    texto={c.texto}
                                    onClick={(se) => {
                                        let build_categorias = [...categoriasSeleccionadas]
                                        let buscar_categoria = categoriasSeleccionadas.find((categoria) => categoria.id === c.id)
                                        if (!buscar_categoria) {
                                            build_categorias = [...categoriasSeleccionadas, {
                                                id: c.id,
                                                value: c,
                                                estatus: true
                                            }]

                                        } else {
                                            build_categorias = categoriasSeleccionadas.map((categoria) => {
                                                if (categoria.id === c.id) {
                                                    return {
                                                        ...categoria,
                                                        estatus: !se
                                                    }
                                                } return categoria

                                            })

                                        }
                                        fijaCategoriasSeleccionadas(build_categorias)
                                        onClick(build_categorias)

                                    }}
                                    icono={`${main_seleccionado ? 'fa-angle-up' : 'fa-angle-down'}`}
                                />
                                {
                                    c.subcategorias.map((s, j) => {
                                        let seleccionado = categoriasSeleccionadas.find((categoria) => categoria.id === s.id)?.estatus
                                        return (
                                            <div key={j} className={`seccion-categorias__contenido__sub-categorias ${main_seleccionado ? 'seccion-categorias__contenido__sub-categorias--visible' : ''}`}>
                                                <CheckBox
                                                    texto={s.texto}
                                                    textoPequeño={true}
                                                    seleccionado={seleccionado}
                                                    onClick={(select) => {
                                                        let build_categorias = [...categoriasSeleccionadas]
                                                        let buscar_categoria = categoriasSeleccionadas.find((categoria) => categoria.id === s.id)
                                                        if (!buscar_categoria) {
                                                            build_categorias = [...categoriasSeleccionadas, {
                                                                id: s.id,
                                                                value: s,
                                                                estatus: true
                                                            }]
                                                        } else {
                                                            build_categorias = categoriasSeleccionadas.map((categoria) => {
                                                                if (categoria.id === s.id) {
                                                                    return {
                                                                        ...categoria,
                                                                        estatus: !select
                                                                    }
                                                                } return categoria

                                                            })

                                                        }
                                                        fijaCategoriasSeleccionadas(build_categorias)
                                                        onClick(build_categorias)
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        )
                    })
                }
            </div>
        </Card>
    )
}

export default Categorias