import { ModalForm, Input, ImageInput } from 'eureka-design'
import { obtenerLogo } from '../../js/util'
import { useState } from 'react'
import { useCrearPacienteMutation } from '../../services/paciente'
import { useDarRolMutation } from '../../services/rol'
import { useCrearMultimediaMutation, useRelacionarMultimediaMutation } from '../../services/multimedia'
import { ROL_PACIENTE } from '../../js/rol'

export const NOM_FORM_PACIENTE = 'paciente'

const FormPaciente = ({ 
    mostrar = false, 
    cambiarEstado = () => {}, 
    UpProps, 
    paciente, 
    foto, 
    actualizarPaciente = () => {},
}) => {
    const [crearPaciente, { isLoading: actualizandoPaciente, isError: pacienteErroneo, isSuccess: pacienteCorrecto }] = useCrearPacienteMutation()
    const [darRol] = useDarRolMutation()
    const [crearMultimedia, { isLoading, isError, isSuccess }] = useCrearMultimediaMutation()
    const [relacionarMultimedia] = useRelacionarMultimediaMutation()

    const [pacienteId, fijaPacienteId] = useState(paciente?.id)
    const [fotoId, fijaFotoId] = useState(foto?.id)

    const handlerAgregarFoto = async (archivo = null) => {
        const usuarioId = (paciente?.id) ? paciente.id :  await handlerAgregar('nombre', '')

        crearMultimedia({
            media: {
                id: fotoId ?? foto?.id,
                file: archivo,
                carpeta: 'foto',
                col: 'nombre',
            }
        })
        .unwrap()
        .then((res) => {
            if (res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaFotoId(id)
                relacionarMultimedia({ id: id, body: { usuario: usuarioId } })
            }
            // else se actualizo el registro
        })
    }

    const handlerAgregar = async (nom, val) => {       
        return await crearPaciente({
            id: pacienteId ?? paciente?.id,
            body: { [nom]: val }
        })
        .unwrap()
        .then(res => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaPacienteId(id)
                darRol({ body: { rol: ROL_PACIENTE.id, usuario: id } })
                return id
            }
            else return pacienteId ?? paciente?.id
        })
    }

    const handlerFoto = (e) => {
        handlerAgregarFoto(e.target.files[0])
        handlerProps('foto', e.target.files[0])
    }

    const handlerInput = (val, nom) => {
        handlerAgregar(nom, val)
        handlerProps(nom, val)
    }

    const handlerProps = (nom, val, extra) => {
        UpProps({
            [NOM_FORM_PACIENTE]: {
                ...paciente,
                id: pacienteId ?? paciente?.id,
                ...extra,
                [nom]: val
            }
        })
    }

    return (
        <ModalForm
            show={mostrar}
            titulo="Agregar un nuevo paciente"
            botones={[
                {
                    texto: "Agregar paciente",
                    onClick: () => { 
                        console.log(paciente)
                        console.log(foto)
                        actualizarPaciente() 
                    },
                }
            ]}
            onClose={() => { cambiarEstado(false) }}
            isLoading={[actualizandoPaciente, isLoading]}
            isError={[pacienteErroneo, isError]}
            isSuccess={[pacienteCorrecto, isSuccess]}
        >
            <ImageInput 
                width={250} 
                height={250} 
                changeFunction={handlerFoto}
                preview={
                    paciente?.foto ?
                        URL.createObjectURL(paciente.foto) : 
                        obtenerLogo(foto, 'foto')
                }
            />
            <br />
            <Input 
                type="text"
                iconLeft="fa-user"
                nombre="nombre"
                placeholder="Nombre"
                value={paciente?.nombre ?? ''}
                changeFunction={handlerInput}
                isLoading={actualizandoPaciente}
            />
            <Input 
                type="text"
                iconLeft="fa-envelope"
                nombre="correo"
                placeholder="Correo electrónico"
                value={paciente?.correo ?? ''}
                changeFunction={handlerInput}
                isLoading={actualizandoPaciente}
            />
            <Input 
                type="text"
                iconLeft="fa-phone"
                nombre="telefono"
                placeholder="Teléfono"
                value={paciente?.telefono ?? ''}
                changeFunction={handlerInput}
                isLoading={actualizandoPaciente}
            />
        </ModalForm>
    )
}

export default FormPaciente