import './CardProducto.css'
import nologo from '../../imgs/no-logo.png'
import { useEffect, useState } from 'react'
import { numberFormat } from '../../js/util'

const CardProducto = ({ 
    producto = {}, 
    modoNormal = true, 
    onClick = () => {},
    permitirEditar = true,
}) => {
    const [precio, fijaPrecio] = useState(null)

    useEffect(() => {
        if(producto?.eureka_st_productos_precios?.length > 0) {
            fijaPrecio(producto.eureka_st_productos_precios[0])
        }
        else {
            fijaPrecio({})
        }
    }, [producto?.eureka_st_productos_precios, fijaPrecio])

    return (
        <div className={`card-producto ${modoNormal ? '' : 'card-producto--inactivo'}`} onClick={onClick}>
            <img className="card-producto__foto" src={nologo} alt="logo" />
            <div className="card-producto__informacion">
                <p className="card-producto__informacion__precio">{numberFormat(precio?.precio)}</p>
                <p className="card-producto__informacion__nombre">{producto?.nombre ?? 'Sin nombre'}</p>
                <p className="card-producto__informacion__ineventario">En inventario: {precio?.numero ?? 0}</p>
            </div>
        </div>
    )
}

export default CardProducto