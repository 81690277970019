import { Formulario, Input, ImageInput } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useCrearDoctorMutation, useCrearDomicilioMutation } from '../../services/doctor'
import { obtenerArchivo, obtenerLogo } from '../../js/util'
import { useCrearMultimediaMutation, useRelacionarMultimediaMutation } from '../../services/multimedia'
import { useDarRolMutation } from '../../services/rol'
import { ROL_DOCTOR } from '../../js/rol'

export const NOM_FORM_DOCTOR = 'doctor'

const styleDisplayFlex = { 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    gap: '8px',
}

const FormDoctor = ({ titulo, doctor, UpTittle, UpProps }) => {
    const [crearDoctor, {isLoading:lDoctor, isError:eDoctor, isSuccess:sDoctor}] = useCrearDoctorMutation()
    const [crearMultimedia, {isLoading:lMultimedia, isError:eMultimedia, isSuccess:sMultimedia}] = useCrearMultimediaMutation()
    const [crearDomicilio, {isLoading:lDomicilio, isError:eDomicilio, isSuccess:sDomicilio}] = useCrearDomicilioMutation()
    const [relacionarMultimedia] = useRelacionarMultimediaMutation()
    const [darRol] = useDarRolMutation()

    const [doctorId, fijaDoctorId] = useState(doctor?.id)
    const [domicilio] = useState(doctor?.eureka_in_domicilios?.length > 0 ? doctor.eureka_in_domicilios[0] : null)
    const [domicilioId, fijaDomicilioId] = useState(domicilio?.id)
    const [multimediaId, fijaMultimediaId] = useState(obtenerArchivo(doctor?.eureka_cr_multimedia, 'foto')?.id)

    useEffect(() => {
        UpTittle(titulo)
    })

    const handlerAgregarFoto = async (archivo = null) => {
        const usuarioId = doctorId ?? doctor.id ?? await handlerCrearDoctor('nombre', '')

        crearMultimedia({
            media: {
                id: multimediaId,
                file: archivo,
                carpeta: 'foto',
                col: 'nombre',
            }
        })
        .unwrap()
        .then((res) => {
            if (res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaMultimediaId(id)
                relacionarMultimedia({ id: id, body: { usuario: usuarioId } })
            }
            // else se actualizo el registro
        })
    }

    const handlerCrearDoctor = async (nom, val) => {
        return await crearDoctor({
            id: doctorId ?? doctor?.id,
            body: {
                [nom]: val
            }
        })
        .unwrap()
        .then(res => {
            if (res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaDoctorId(id)
                darRol({ body: { rol: ROL_DOCTOR.id, usuario: id } })
                return id
            }
            else return doctorId ?? doctor?.id
        })
    }

    const handlerCrearDireccion = async (nom, val) => {
        const usuarioId = doctorId ?? doctor.id ?? await handlerCrearDoctor('nombre', '')

        crearDomicilio({
            id: domicilioId,
            body: {
                [nom]: val,
                usuario: usuarioId,
            }
        })
        .unwrap()
        .then(res => {
            if (res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaDomicilioId(id)
                return id
            }
            else return doctorId
        })
    }

    const handlerFoto = (e) => {
        handlerAgregarFoto(e.target.files[0])
        handlerProps('foto', e.target.files[0])
    }

    const handlerInputInfo = (val, nom) => {
        handlerCrearDoctor(nom, val)
        handlerProps(nom, val)
    }

    const handlerInputDireccion = (val, nom) => {
        handlerCrearDireccion(nom, val)
        if(nom === 'nombre') {
            handlerProps('nombre_direccion', val)
        }
        else {
            handlerProps(nom, val)
        }
    }

    const handlerProps = (nom, val, extra) => {
        UpProps({
            [NOM_FORM_DOCTOR]: {
                ...doctor,
                id: doctorId ?? doctor?.id,
                ...extra,
                [nom]: val,
            }
        })
    }

    return (
        <Formulario
            titulo="Ingresar los datos para dar de alta al doctor"
            // botones={[
            //     { texto: 'Agregar doctor' }
            // ]}
            isLoading={[lDoctor, lMultimedia, lDomicilio]}
            isError={[eDoctor, eMultimedia, eDomicilio]}
            isSuccess={[sDoctor, sMultimedia, sDomicilio]}
        >
            <ImageInput 
                width={250} 
                height={250} 
                changeFunction={handlerFoto}
                preview={obtenerLogo(doctor?.eureka_cr_multimedia, 'foto')}
            />
            <br />
            <Input 
                nombre="nombre"
                placeholder="Nombre"
                iconLeft="fa-user"
                value={doctor?.nombre ?? ''}
                changeFunction={handlerInputInfo}
                isLoading={lDoctor} 
            />
            <Input 
                nombre="correo"
                placeholder="Correo eléctronico"
                iconLeft="fa-envelope"
                value={doctor?.correo ?? ''}
                changeFunction={handlerInputInfo}
                isLoading={lDoctor} 
            />
            <Input 
                nombre="telefono"
                placeholder="Teléfono" 
                iconLeft="fa-phone"
                value={doctor?.telefono ?? ''}
                changeFunction={handlerInputInfo}
                isLoading={lDoctor} 
            />
            <p><b>Dirección</b></p>
            <div style={styleDisplayFlex}>
                <Input 
                    placeholder="Calle"
                    nombre="calle"
                    value={doctor?.calle ?? domicilio?.calle ?? ''}
                    changeFunction={handlerInputDireccion}
                    isLoading={lDomicilio}
                />
                <Input 
                    style={{ width: '25%' }}
                    placeholder="N° exterior" 
                    nombre="numero"
                    value={doctor?.numero ?? domicilio?.numero ?? ''}
                    changeFunction={handlerInputDireccion}
                    isLoading={lDomicilio}
                />
                <Input 
                    style={{ width: '25%' }}
                    placeholder="N° interior"
                    nombre="interior"
                    value={doctor?.interior ?? domicilio?.interior ?? ''}
                    changeFunction={handlerInputDireccion}
                    isLoading={lDomicilio} 
                />
            </div>
            <div style={styleDisplayFlex}>
                <Input 
                    style={{ width: '50%' }}
                    placeholder="Código Postal"
                    nombre="referencias"
                    value={doctor?.referencias ?? domicilio?.referencias ?? ''}
                    changeFunction={handlerInputDireccion}
                    isLoading={lDomicilio} 
                />
                <Input 
                    placeholder="Municipio" 
                    nombre="nombre"
                    value={doctor?.nombre_direccion ?? domicilio?.nombre ?? ''}
                    changeFunction={handlerInputDireccion}
                    isLoading={lDomicilio} 
                />
                <Input 
                    placeholder="Colonia" 
                    nombre="entrecalles"
                    value={doctor?.entrecalles ?? domicilio?.entrecalles ?? ''}
                    changeFunction={handlerInputDireccion}
                    isLoading={lDomicilio} 
                />
            </div>
        </Formulario>
    )
}

export default FormDoctor