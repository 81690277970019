import { Tabla, buildData } from 'eureka-design'
import { useMemo } from 'react'

const Ganancia = () => {
    const data = useMemo(() => {
        return [
            { id: 123, fecha: '2024-08-12', nombre: 'Francisco Alejandro', total: 2000, ganancia: 200 },
            { id: 123, fecha: '2024-08-12', nombre: 'Francisco Alejandro', total: 2000, ganancia: 200 },
            { id: 123, fecha: '2024-08-12', nombre: 'Francisco Alejandro', total: 2000, ganancia: 200 },
        ]
    }, [])

    return (
        <Tabla
            checkbox={false}
            rowSize="medium"
            noData="No hay ganancias aún"
            headers={[
                { key: 'fecha',    text: 'Fecha',        type: 'date' },
                { key: 'nombre',   text: 'Nombre',       type: 'text' },
                { key: 'id',       text: 'Receta ID',    type: 'text' },
                { key: 'total',    text: 'Total receta', type: 'precio' },
                { key: 'ganancia', text: 'Ganancia',     type: 'precio' }
            ]}
            data={buildData(data, [
                { key: 'fecha',    type: 'date' },
                { key: 'nombre',   type: 'text' },
                { key: 'id',       type: 'text' },
                { key: 'total',    type: 'precio' },
                { key: 'ganancia', type: 'precio' }
            ])}
        />
    )
}

export default Ganancia