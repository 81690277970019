import { Input, Select } from 'eureka-design'
import './SeccionFormMedicamento.css'
import { adaptarDatos } from '../../../js/selector'
import { tiempos } from '../../../js/receta'

const SeccionFormMedicamento = ({ 
    medicamentos = [], 
    onChange = () => {},
    detalleMedicamento = {}
}) => {
    const handlerInput = (val, nom) => {
        onChange(nom, val, 'input')
    }

    const handlerSelect = (val, nom) => {
        onChange(nom, val, 'select')
    }

    return (
        <div className="seccion-principal">
            <div className="seccion-principal__input-principal">
                <Select 
                    placeholder="Buscar medicamento"
                    nombre="producto"
                    options={adaptarDatos(medicamentos, 'nombre', 'id')}
                    value={detalleMedicamento?.producto_texto ?? ''}
                    busqueda={{
                        placeholder: 'Buscar...'
                    }}
                    changeFunction={handlerSelect}
                />
            </div>

            <div className="seccion-principal__input" style={{ width: '13%' }}>
                <p className="seccion-principal__input__placeholder">Tomar</p>
                <div className="seccion-principal__input__input" style={{ width: '60%' }}>
                    <Input 
                        type="number"
                        nombre="cantidad"
                        value={detalleMedicamento?.cantidad ?? '00'}
                        changeFunction={handlerInput}
                    />
                </div>
            </div>

            <div className="seccion-principal__input">
                <p className="seccion-principal__input__placeholder">Pastilla(s) cada</p>
                <div className="seccion-principal__input__input">
                    <Input 
                        type="text"
                        nombre="clave"
                        value={detalleMedicamento?.clave ?? '00'}
                        changeFunction={handlerInput}
                    />
                </div>
            </div>

            <div className="seccion-principal__input" style={{ width: '18%' }}>
                <p className="seccion-principal__input__placeholder">Horas durante</p>
                <div className="seccion-principal__input__input">
                    <Input 
                        type="number"
                        nombre="nombre"
                        value={detalleMedicamento?.nombre ?? '00'}
                        changeFunction={handlerInput}
                    />
                </div>
            </div>
            
            <div className="seccion-principal__input-secundario">
                <Select
                    placeholder="Periodo"
                    nombre="tipo"
                    options={adaptarDatos(tiempos, 'texto', 'texto')}
                    value={detalleMedicamento?.tipo_texto ?? ''}
                    changeFunction={handlerSelect}
                />
            </div>
        </div>
    )
}

export default SeccionFormMedicamento