import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'
import { ROL_PACIENTE } from '../js/rol'

const TABLA_PACIENTE = 'EurekaCrRol'
const TABLA_PACIENTE_FORM = 'EurekaAcUsuario'

export const pacienteAPI = createApi({
    reducerPath: 'pacienteAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Pacientes'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerPacientes: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_PACIENTE)
                                .where('nombre', ROL_PACIENTE.nombre)
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_bs_empleados.eureka_in_domicilio')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_cr_multimedia')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones')
                                .get()
                }
            },
            transformResponse: (data) => {
                data = data[0]?.eureka_cr_rol_usuarios
                return data.map((guardia) => guardia?.eureka_ac_usuario)
            }
        }),
        crearPaciente: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PACIENTE_FORM)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const { 
    useObtenerPacientesQuery,
    useCrearPacienteMutation,
} = pacienteAPI