import './CheckBox.css'

const CheckBox = ({
    texto = 'Producto 1',
    textoPequeño = false,
    seleccionado = false,
    onClick = () => { },
    icono = null
}) => {
    return (
        <div className="contenedor-checkbox-categoria" onClick={(e) => {
            e.stopPropagation()
            onClick(seleccionado)
        }}>
            <div className="checkbox-categoria">
                <div className={`checkbox-categoria__seleccion ${seleccionado ? 'checkbox-categoria__seleccion--visible' : ''}`}></div>
            </div>
            <p
                className={`checkbox-categoria__texto ${textoPequeño ? 'checkbox-categoria__texto--sm' : ''}`}
            >
                {texto}
            </p>
            <i className={`fa-solid ${icono ? icono : ''}`}></i>
        </div>
    )
}

export default CheckBox