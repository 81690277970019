import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_MEDICINA = 'EurekaStProducto'
const TABLA_CATEGORIA = 'EurekaStNivele'

export const medicinaAPI = createApi({
    reducerPath: 'medicinaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Medicinas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerMedicinas: builder.query({
            /**
             * 
             * @param {{ estatus: string, nivel: number }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MEDICINA)
                body = data?.niveles ? body.whereIn('nivel', data.niveles) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with(['eureka_st_productos_precios', "eureka_st_transacciones_conceptos", "eureka_st_productos_caracteristicas", "eureka_st_productos_almacenes",
                        "eureka_st_nivele"
                    ]).get()
                }
            },
        }),
        obtenerCategorias: builder.query({
            query: () => {
                let body = new EkQuery(TABLA_CATEGORIA)
                body = body.where('estatus', 'activo').has('eureka_st_niveles_rutas')

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
        }),
    }),
})


export const {
    useObtenerMedicinasQuery,
    useObtenerCategoriasQuery,
} = medicinaAPI