import CardDetalle from "../../components/CardDetalle/CardDetalle"
import ModalDetalles from "../../components/ModalDetalles/ModalDetalles"
import SeccionInformacion from "../../components/SeccionInformacion/SeccionInformacion"
import { numberFormat } from "../../js/util"
// import { obtenerFoto } from '../../js/multimedia'
import { Input, Tabla, buildData, ChipContact, ChipEstatus, Select, PerfilDetalleInfo, PerfilDetalleSection } from 'eureka-design'
import { useState, useEffect } from "react"


const estiloSubTitulo = {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '16px',
}

const estiloDisplayFlex = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px'
}

const InfoMedicina = ({
    mostrar = true,
    onClose = () => { },
    producto
}) => {
    const [Medicina, setMedicina] = useState(producto ?? {})
    const [fecha_ganancias, setFechaGanancias] = useState({
        fecha_inicial: new Date().setHours(0, 0, 0, 0),
        fecha_final: new Date().setHours(23, 59, 59, 999)
    })
    const [principios_activos, fijaActivos] = useState([])
    const [total_inventario, setTotalInventario] = useState(0)
    const [totales_recetas, setTotalesRecetas] = useState(0)


    useEffect(() => {
        if (producto) {
            console.log(producto)
            setMedicina(producto)
        }

    }, [producto])


    useEffect(() => {
        control_totales_ganancias(producto)
    }, [fecha_ganancias, producto])


    const control_totales_ganancias = (medicina) => {
        let ganancias = 0;
        let inventario = 0;
        let movimientos = 0;
        let principios = []

        medicina?.eureka_st_transacciones_conceptos?.forEach((movimiento) => {
            let fecha_movimiento = new Date(movimiento.fecha)
            if (fecha_ganancias.fecha_inicial <= fecha_movimiento && fecha_movimiento <= fecha_ganancias.fecha_final) {
                ganancias += movimiento.total
                movimientos++
            }
        })

        medicina?.eureka_st_productos_caracteristicas?.forEach((caracteristica) => {
            principios.push(caracteristica)
        })

        if (medicina?.eureka_st_productos_almacenes?.length > 0) {
            inventario = medicina.eureka_st_productos_almacenes[0].cantidad
        }
        setTotalInventario(inventario)
        setTotalesRecetas(movimientos)
        fijaActivos(principios)
    }

    const estatus_chip = () => {
        let texto = ""
        let tipo = ""
        if (Medicina.estatus === 'funcionando') {
            texto = "En servicio"
            tipo = "Aceptado"
        } else if (Medicina.estatus === 'mantenimiento') {
            texto = "En mantenimiento"
            tipo = "Pendiente"
        } else if (Medicina.estatus === 'reparacion') {
            texto = "Descompuesta"
            tipo = "Error"
        }
        return <ChipEstatus texto={texto} tipo={tipo} />
    }

    return <>
        <ModalDetalles
            mostrar={mostrar}
            onClose={onClose}
            titulo="Información del producto    "
        >
            <SeccionInformacion>

                <h2 style={estiloSubTitulo}>Detalles del producto</h2>
                <PerfilDetalleSection titulo={"Clave o código"}>
                    <span>{Medicina.codigo}</span>
                </PerfilDetalleSection>
                <PerfilDetalleSection titulo={"Categoría"}>
                    <span>{Medicina?.eureka_st_nivele?.nombre ?? ""}</span>
                </PerfilDetalleSection>
                <PerfilDetalleSection titulo={"Descripción"}>
                    <span>{Medicina.descripcion}</span>
                </PerfilDetalleSection>
                {
                    Medicina?.eureka_st_productos_precios?.map((precio) => {
                        return <PerfilDetalleSection titulo={`Precio ${precio.numero}`}>
                            <span>{numberFormat(precio.precio)}</span>
                        </PerfilDetalleSection>
                    })
                }

                <h2 style={estiloSubTitulo}>Lista de principios activos</h2>
                <Tabla
                    shadow={false}
                    headers={[
                        { key: 'caracteristica', text: 'Activo', type: 'text' },
                        { key: 'valor', text: 'Cantidad', type: 'text' },
                    ]}
                    data={
                        buildData(principios_activos ?? [], [
                            { key: 'caracteristica', type: 'text' },
                            { key: 'valor', type: 'text' },
                        ])
                    }
                />
                <h2 style={estiloSubTitulo}>
                    Recetas totales de este producto - {totales_recetas > 0 ? totales_recetas : "--"}
                </h2>

                <div style={estiloDisplayFlex}>
                    <Input
                        placeholder={"Rango de fechas"}
                        name={"Fecha"}
                        type={"date"}
                        changeFunction={(value) => {
                            setFechaGanancias({
                                fecha_inicial: value.date1.setHours(0, 0, 0, 0),
                                fecha_final: value.date2.setHours(23, 59, 59, 999)
                            })
                        }}
                        dateConfig={{
                            timePicker: false,
                            rangePicker: true,
                            formato: "{SS} {DD}/{MM}/{YY}",
                        }}
                        iconLeft={"fa-calendar"}
                    ></Input>
                </div>
                <span>{`${new Date(fecha_ganancias.fecha_inicial).toLocaleDateString()} al ${new Date(fecha_ganancias.fecha_final).toLocaleDateString()}`}</span>
                <div style={estiloDisplayFlex}>
                    <CardDetalle
                        titulo="Recetas totales"
                        texto={numberFormat(totales_recetas)}
                        mostrarIcono={false}
                    />
                    <CardDetalle
                        titulo="Inventario total"
                        texto={total_inventario}
                        mostrarIcono={false}
                    />
                </div>
            </SeccionInformacion>
        </ModalDetalles>
    </>


}

export default InfoMedicina