import { Tabla, buildData, ChipEstatus, ButtonText } from 'eureka-design'
import { useEffect, useState } from 'react'
import FormPaciente, { NOM_FORM_PACIENTE } from './form-paciente'
import { useObtenerPacientesQuery } from '../../services/paciente'
import { obtenerArchivo } from '../../js/util'


const ListaPacientes = ({ UpProps, Push, ShowAlert, paciente }) => {
    const { data, isLoading, refetch } = useObtenerPacientesQuery()

    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalArchivados, fijaTotalArchivados] = useState(0)
    const [mostrarModal, fijaMostrarModal] = useState(false)

    useEffect(() => {
        const calcularTotal = (pacientes = [], estatus = 'activo') => {
            return pacientes.filter((paciente) => paciente?.estatus === estatus).length
        }

        fijaTotalActivos(calcularTotal(data, 'activo'))
        fijaTotalArchivados(calcularTotal(data, 'inactivo'))
    }, [data])

    const handlerRegistrar = () => {
        UpProps({
            [NOM_FORM_PACIENTE]: {}
        })
        fijaMostrarModal(true)
    }

    const handlerEditar = (item) => {
        UpProps({
            [NOM_FORM_PACIENTE]: {
                ...item.item
            }
        })
        fijaMostrarModal(true)
    }

    const handlerArchivar = (item) => {
        console.log('archivar paciente...')
    }

    const handlerVerDetalles = (item) => {

    }

    const handlerAgregarReceta = (item) => {
        Push('form_receta', {
            paciente: item,
            titulo: `Nueva receta para: ${item.nombre}`
        })
    }

    if(isLoading) return
    else {
        return (
            <>
                <FormPaciente 
                    mostrar={mostrarModal}
                    cambiarEstado={fijaMostrarModal}
                    UpProps={UpProps}
                    paciente={paciente}
                    foto={obtenerArchivo(paciente?.eureka_cr_multimedia, 'foto')}
                    actualizarPaciente={refetch}
                />
                
                <Tabla 
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay pacientes registrados"
                    busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                    keys={[
                        {
                            icono: 'fa-users',
                            text: "Todos",
                            detalle: totalActivos + totalArchivados,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Activos",
                            detalle: totalActivos,
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Archivados",
                            detalle: totalArchivados,
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    headers={[
                        { key: 'nombre',   text: 'Nombre',             type: 'text' },
                        { key: 'recetas',  text: 'Recetas emitidas',   type: 'text' },
                        { key: 'correo',   text: 'Correo electrónico', type: 'text' },
                        { key: 'telefono', text: 'Teléfono',           type: 'text' },
                        { key: 'estatus',  text: 'Estado',             type: 'text' },
                        { key: 'agregar',  text: '',                   type: 'text' },
                    ]}
                    data={buildData(data, [
                        { key: 'nombre',   type: 'text' },
                        { 
                            key: 'recetas',
                            type: 'text',
                            text: (item) => {
                                return `10`
                            }
                        },
                        { key: 'correo',   type: 'text' },
                        { key: 'telefono', type: 'text' },
                        { 
                            key: 'estatus',  
                            type: 'text',
                            text: (item) => {
                                const paciente = item
                                let texto, tipo

                                if(paciente.estatus === 'activo') {
                                    texto = 'Activo'
                                    tipo = 'Aceptado'
                                }
                                else {
                                    texto = 'Suspendido'
                                    tipo = 'Error' 
                                }

                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                        {
                            key: 'agregar', 
                            type: 'text',
                            text: (item) => {
                                return (
                                    <ButtonText 
                                        icono="fa-file-circle-plus" 
                                        texto="Agregar receta" 
                                        onClick={() => { handlerAgregarReceta(item) }}
                                    />
                                )
                            } 
                        }
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Registrar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-pen',       tooltip: 'Editar',   onClick: handlerEditar },
                        { icono: 'fa-trash-can', tooltip: 'Archivar', onClick: handlerArchivar },
                    ]}
                    onClickRow={handlerVerDetalles}
                />
            </>
        )
    }
}

export default ListaPacientes