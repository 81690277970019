import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useObtenerFarmaciasQuery } from '../../services/farmacia'

const ListaFarmacias = () => {
    const { data, isLoading } = useObtenerFarmaciasQuery()

    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalArchivados, fijaTotalArchivados] = useState(0)

    useEffect(() => {
        const calcularTotal = (pacientes = [], estatus = 'activo') => {
            return pacientes.filter((paciente) => paciente?.estatus === estatus).length
        }

        fijaTotalActivos(calcularTotal(data, 'activo'))
        fijaTotalArchivados(calcularTotal(data, 'inactivo'))
    }, [data])

    if(isLoading) return
    else {
        return (
            <Tabla 
                checkbox={false}
                rowSize="medium"
                noData="No hay farmacias registradas"
                busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                keys={[
                    {
                        icono: 'fa-users',
                        text: "Todos",
                        detalle: totalActivos + totalArchivados,
                        filtro: () => true
                    },
                    {
                        icono: 'fa-circle-check',
                        text: "Activos",
                        detalle: totalActivos,
                        filtro: item => item.item.estatus === "activo"
                    },
                    {
                        icono: 'fa-triangle-exclamation',
                        text: "Archivados",
                        detalle: totalArchivados,
                        filtro: item => item.item.estatus === "inactivo"
                    },
                ]}
                headers={[
                    { key: 'nombre',      text: 'Nombre',    type: 'text' },
                    { key: 'direccion',   text: 'Dirección', type: 'text' },
                    { key: 'numerolocal', text: 'Teléfono',  type: 'text' },
                    { key: 'estatus',     text: 'Estado',    type: 'text' },
                ]}
                data={buildData(data, [
                    { key: 'nombre',      type: 'text' },
                    { 
                        key: 'direccion',
                        type: 'text',
                        text: (item) => {
                            return `Av. Principal, Calle A 123`
                        }
                    },
                    { key: 'numerolocal', type: 'text' },
                    { 
                        key: 'estatus',  
                        type: 'text',
                        text: (item) => {
                            const paciente = item
                            let texto, tipo
                            if(paciente.estatus === 'activo') {
                                texto = 'Activa'
                                tipo = 'Aceptado'
                            }
                            else {
                                texto = 'Suspendida'
                                tipo = 'Error' 
                            }
                            return <ChipEstatus texto={texto} tipo={tipo}/>
                        }
                    },
                ])}
                botonesHead={[
                    { icono: 'fa-plus', tooltip: 'Agregar', onClick: () => {} },
                ]}
                botonesRow={[
                    { icono: 'fa-pen',       tooltip: 'Editar',   onClick: () => {} },
                    { icono: 'fa-trash-can', tooltip: 'Archivar', onClick: () => {} },
                ]}
                onClickRow={() => {}}
            />
        )
    }
}

export default ListaFarmacias