import ListaArchivo from '../../components/ListaArchivo/ListaArchivo'
import { config } from '../../config/config'
import { descargar, obtenerArchivo } from '../../js/util'
import { useCrearMultimediaMutation, useRelacionarMultimediaMutation } from '../../services/multimedia'
import { crearCookie } from 'account-react-eurekasigma'

const Documentacion = ({ usuario, ShowAlert, UpProps }) => {
    const [crearMultimedia] = useCrearMultimediaMutation()
    const [relacionarMultimedia] = useRelacionarMultimediaMutation()

    const handlerActualizarArchivosLocal = (archivo, nombre, usuario) => {
        archivo = {
            ...archivo,
            archivo: nombre,
            usuario: usuario?.id,
            tipo: 'archivo',
        }
        usuario = {
            ...usuario,
            eureka_cr_multimedia: [
                ...usuario?.eureka_cr_multimedia,
                ...[archivo]
            ]
        }
        crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, config.expCookie)
        return usuario
    }

    const handlerAgregarDocumento = (archivo, nombre, nombreReal, tipo) => {
        crearMultimedia({
            media: { file: archivo, carpeta: nombre, col: 'nombre' }
        })
        .unwrap()
        .then((res) => {
            if (res?.mensaje?.id) {
                let archivo = res.mensaje
                let usuarioActualizado = handlerActualizarArchivosLocal(archivo, nombreReal, usuario)

                UpProps({ usuario: usuarioActualizado})
                relacionarMultimedia({ 
                    id: archivo.id, 
                    body: { 
                        usuario: usuario.id,
                        archivo: nombreReal,
                        tipo: 'archivo',
                    } 
                })
                ShowAlert({
                    icono: 'success',
                    titulo: 'Éxito',
                    mensaje: 'El archivo se cargo correctamente',
                    time: 3,
                })
            }
            // else se actualizo el registro
        })
    }

    const handlerQuitarDocumento = (archivo) => {
        let archivos = usuario?.eureka_cr_multimedia?.filter((a) => a.id !== archivo.id)
        let usuarioActualizado = {
            ...usuario,
            eureka_cr_multimedia: archivos
        }
        crearCookie(config.nomCookieUsuario, JSON.stringify(usuarioActualizado), true, config.expCookie)
        UpProps({ usuario: usuarioActualizado})

        relacionarMultimedia({ 
            id: archivo.id, 
            body: { usuario: null } 
        })
    }

    const handlerDescargar = (archivo) => {
        descargar(archivo, archivo.nombre)
    }

    return (
        <>
            <ListaArchivo 
                nombre="documento"
                tipoIcono="fa-regular"
                icono="fa-circle-user"
                colorIcono="#CE54EB"
                titulo="Personales"
                archivos={obtenerArchivo(usuario?.eureka_cr_multimedia, 'documento', true)}
                extencionesPermitidas={['.pdf', '.png', '.jpg']}
                handlerCambiar={handlerAgregarDocumento}
                handlerQuitar={handlerQuitarDocumento}
                handlerDescargar={handlerDescargar}
            />
            <br />
            <ListaArchivo
                nombre="cedula"
                icono="fa-briefcase"
                colorIcono="#00D385"
                titulo="Cédula"
                agregarInput={true}
                configInput={{
                    placeholder: 'Ingresa tu número de cédula',
                    nombre: 'num_cedula',
                    value: '',
                }}
                archivos={obtenerArchivo(usuario?.eureka_cr_multimedia, 'cedula', true)}
                extencionesPermitidas={['.pdf']}
                handlerCambiar={handlerAgregarDocumento}
                handlerQuitar={handlerQuitarDocumento}
                handlerDescargar={handlerDescargar}
            />
            <br />
            <ListaArchivo
                nombre="certificado"
                icono="fa-book-open-reader"
                colorIcono="#FFB500"
                titulo="Certificados"
                archivos={obtenerArchivo(usuario?.eureka_cr_multimedia, 'certificado', true)}
                extencionesPermitidas={['.pdf']}
                handlerCambiar={handlerAgregarDocumento}
                handlerQuitar={handlerQuitarDocumento}
                handlerDescargar={handlerDescargar}
            />
            <br />
            <ListaArchivo
                nombre="formacion"
                icono="fa-book-open-reader"
                colorIcono="#FFB500"
                titulo="Formación"
                archivos={obtenerArchivo(usuario?.eureka_cr_multimedia, 'formacion', true)}
                extencionesPermitidas={['.pdf', '.docx', '.png']}
                handlerCambiar={handlerAgregarDocumento}
                handlerQuitar={handlerQuitarDocumento}
                handlerDescargar={handlerDescargar}
            />
        </>
    )
}

export default Documentacion