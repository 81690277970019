import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_RECETA = 'EurekaStTransaccione'

export const recetaAPI = createApi({
    reducerPath: 'recetaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Recetas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerRecetas: builder.query({
            /**
             * 
             * @param {{ estatus: string }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_RECETA)
                body = data?.estatus ? body.where('estatus', data.estatus) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                            .with('eureka_st_transacciones_conceptos')
                            .with('eureka_ac_usuario')
                            .get()
                }
            },
        }),
        crearReceta: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_RECETA)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const { 
    useObtenerRecetasQuery,
    useCrearRecetaMutation,
} = recetaAPI