import { Tabla, buildData, Miniatura } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useObtenerRecetasQuery } from '../../services/receta'
import { numberFormat, obtenerLogo } from '../../js/util'

const ListaRecetas = ({ Push }) => {
    const { data, isLoading } = useObtenerRecetasQuery()

    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalArchivados, fijaTotalArchivados] = useState(0)

    useEffect(() => {
        const calcularTotal = (pacientes = [], estatus = 'activo') => {
            return pacientes.filter((paciente) => paciente?.estatus === estatus).length
        }

        fijaTotalActivos(calcularTotal(data, 'activo'))
        fijaTotalArchivados(calcularTotal(data, 'inactivo'))
    }, [data])

    const handlerRegistrar = () => {
        Push('form_receta', {
            titulo: 'Nueva receta',
        })
    }

    const handlerEditar = (item) => {

    }

    const handlerArchivar = (item) => {
        
    }

    const handlerVerDetalles = (item) => {
        Push('detalles_receta', {
            titulo: `Receta ${item.item.id}`,
            receta: item.item
        })
    }

    if(isLoading) return
    else {
        return (
            <>            
                <Tabla 
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay recetas registradas"
                    busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                    keys={[
                        {
                            icono: 'fa-users',
                            text: "Todos",
                            detalle: totalActivos + totalArchivados,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Activos",
                            detalle: totalActivos,
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Archivados",
                            detalle: totalArchivados,
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    headers={[
                        { key: 'fecha',        text: 'Fecha',        type: 'date' },
                        { key: 'id',           text: 'Recetas ID',   type: 'text' },
                        { key: 'nombre',       text: 'Nombre',       type: 'text' },
                        { key: 'total',        text: 'Total receta', type: 'precio' },
                        { key: 'ganancia',     text: 'Ganancia',     type: 'precio' },
                        { key: 'medicamentos', text: 'Medicamentos', type: 'text' },
                    ]}
                    data={buildData(data, [
                        { key: 'fecha',        type: 'date' },
                        { key: 'id',           type: 'text' },
                        { 
                            key: 'nombre',       
                            type: 'text',
                            text: (item) => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <Miniatura src={obtenerLogo(item?.eureka_ac_usuario?.eureka_cr_multimedia, 'foto')} avatar={true} />
                                        <span>{item?.eureka_ac_usuario?.nombre}</span>
                                    </div>
                                )
                            }
                        },
                        { key: 'total',        type: 'precio' },
                        { 
                            key: 'ganancia',
                            type: 'precio',
                            text: (item) => {
                                return numberFormat(20)
                            }
                        },
                        { 
                            key: 'medicamentos', 
                            type: 'text',
                            text: (item) => `${1} medicamento(s) recetado(s)` 
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-pen',       tooltip: 'Editar',   onClick: handlerEditar },
                        { icono: 'fa-trash-can', tooltip: 'Archivar', onClick: handlerArchivar },
                    ]}
                    onClickRow={handlerVerDetalles}
                />
            </>
        )
    }
}

export default ListaRecetas