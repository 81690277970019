import { useEffect } from "react"
import CardInformacion from "../../components/CardInformacion/CardInformacion"
import { descargar, obtenerArchivo, obtenerLogo } from "../../js/util"
import ListaArchivo from "../../components/ListaArchivo/ListaArchivo"

const InfoDoctor = ({ doctor, UpTittle }) => {

    useEffect(() => {
        UpTittle(`Información ${doctor?.nombre}`)    
    })

    const handlerDescargar = (archivo) => {
        descargar(archivo, archivo.nombre)
    }

    return (
        <div>
            <CardInformacion 
                foto={obtenerLogo(doctor.eureka_cr_multimedia, 'foto')}
                titulo={doctor?.nombre}
                informacion={[
                    { etiqueta: 'Cédula', valor: '2345678i999' },
                    { etiqueta: 'Correo electrónico', valor: doctor?.correo },
                    { etiqueta: 'Télefono', valor: doctor?.telefono },
                    { etiqueta: 'Consultorio/Hospital', valor: 'Hospital General - Calle 2 N°123 Colonia Madrigal, Estado de México, México' },
                ]}
                permitirEditar={false}
            />
            <br />

            <ListaArchivo 
                tipoIcono="fa-regular"
                icono="fa-circle-user"
                colorIcono="#CE54EB"
                titulo="Personales"
                archivos={obtenerArchivo(doctor.eureka_cr_multimedia, 'documento', true)}
                agregarBtn={false}
                permitirEliminar={false}
                handlerDescargar={handlerDescargar}
            />
            <br />
            
            <ListaArchivo
                icono="fa-briefcase"
                colorIcono="#00D385"
                titulo="Cédula"
                archivos={obtenerArchivo(doctor.eureka_cr_multimedia, 'cedula', true)}
                agregarBtn={false}
                permitirEliminar={false}
                handlerDescargar={handlerDescargar}
            />
            <br />
            
            <ListaArchivo
                icono="fa-book-open-reader"
                colorIcono="#FFB500"
                titulo="Certificados"
                archivos={obtenerArchivo(doctor.eureka_cr_multimedia, 'certificado', true)}
                agregarBtn={false}
                permitirEliminar={false}
                handlerDescargar={handlerDescargar}
            />
            <br />
            
            <ListaArchivo
                icono="fa-book-open-reader"
                colorIcono="#FFB500"
                titulo="Formación"
                archivos={obtenerArchivo(doctor.eureka_cr_multimedia, 'formacion', true)}
                agregarBtn={false}
                permitirEliminar={false}
                handlerDescargar={handlerDescargar}
            />
        </div>
    )
}

export default InfoDoctor