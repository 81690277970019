import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'
import { ROL_DOCTOR } from '../js/rol'

const TABLA_DOCTOR = 'EurekaCrRol'
const TABLA_DOCTOR_FORM = 'EurekaAcUsuario'
const TABLA_EMPLEADO = 'EurekaBsEmpleado'
const TABLA_DOMICILIO = 'EurekaInDomicilio'

export const doctorAPI = createApi({
    reducerPath: 'doctorAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Doctores'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerDoctores: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_DOCTOR)
                                .where('nombre', ROL_DOCTOR.nombre)
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_in_domicilios')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_cr_multimedia')
                                .get()
                }
            },
            transformResponse: (data) => {
                data = data[0]?.eureka_cr_rol_usuarios
                data = data.filter((guardia) => guardia?.eureka_ac_usuario?.estatus !== 'bloqueado')
                return data.map((guardia) => guardia?.eureka_ac_usuario)
            }
        }),
        crearDoctor: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_DOCTOR_FORM)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearEmpleado: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_EMPLEADO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearDomicilio: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_DOMICILIO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const { 
    useObtenerDoctoresQuery,
    useCrearDoctorMutation,
    useCrearEmpleadoMutation,
    useCrearDomicilioMutation,
} = doctorAPI