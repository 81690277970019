import { useRef } from 'react'
import './ButtonAgregar.css'

const ButtonAgregar = ({ 
    titulo = 'Añadir', 
    nombre = 'archivo',
    allowedTypes = [], 
    allowedExtensions = [], 
    onChange = () => {} 
}) => {
    const inputFile = useRef(null)

    const isValidFileType = (fileType, fileName) => {
        if (allowedTypes.length === 0 && allowedExtensions.length === 0) return true
        else {
            for(const allowedType of allowedTypes) {
                const typePattern = new RegExp(
                    '^' +
                    allowedType
                        .split('/')
                        .map((part) => {
                            return part === '*' ? '.*' : part;
                        })
                        .join('/')
                    + '$'
                )
                if (typePattern.test(fileType)) return true
            }

            const fileExtension = "." + fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
            for(const allowedExtension of allowedExtensions) {
                if (fileExtension.toLowerCase() === allowedExtension.toLowerCase()) return true
            }
            return false
        }
    };


    const handleDrop = (e) => {
        e.preventDefault()
        const droppedFile = e.dataTransfer.files[0]
        if (isValidFileType(droppedFile?.type, droppedFile?.name)) {
            if (onChange) onChange(droppedFile, nombre, droppedFile?.name, droppedFile?.type)
        } 
        // else no agregar el archivo   
    }

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0]
        if (isValidFileType(selectedFile?.type, selectedFile?.name)) {
            if (onChange) onChange(selectedFile, nombre, selectedFile?.name, selectedFile?.type)
        }
        // else no agregar el archivo  
    }

    return (
        <div 
            className="button-agregar-archivo"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            onClick={() => { inputFile.current.click() }}
        >
            <p className="button-agregar-archivo__texto">{titulo}</p>
            <i className="fa-solid fa-plus"></i>
            <input 
                ref={inputFile} 
                type="file" 
                accept={allowedTypes.join(', ') + ", " + allowedExtensions.join(', ')}
                style={{ display: 'none' }} 
                onChange={handleFileInputChange}
            />
        </div>
    )
}

export default ButtonAgregar