import { useEffect, useState } from 'react'
import { Formulario, Input, Select, ModalForm, LoaderCircle } from 'eureka-design'
import { useObtenerPacientesQuery } from '../../services/paciente'
import { adaptarDatos } from '../../js/selector'
import { useCrearRecetaMutation } from '../../services/receta'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { adaptarFechaBD } from '../../js/util'
import { useObtenerMedicinasQuery } from '../../services/medicina'
import SeccionFormMedicamento from '../../components/Receta/SeccionFormMedicamento/SeccionFormMedicamento'
import ListaMedicamentos from '../../components/Receta/ListaMedicamentos/ListaMedicamentos'

export const NOM_FORM_RECETA = 'receta'

const FormReceta = ({ UpProps, UpTittle, titulo, receta, paciente = null }) => {
    const { data:medicamentos, isLoading:cargandoMedicamentos } = useObtenerMedicinasQuery()
    const { data:pacientes, isLoading } = useObtenerPacientesQuery()

    const [crearReceta, {isLoading:lReceta, isError:eReceta, isSuccess:sReceta}] = useCrearRecetaMutation()

    const [doctor] = useState(infoUsuario())
    const [recetaId, fijaRecetaId] = useState(receta?.id)
    const [mostrarModal, fijaMostrarModal] = useState(false)
    const [detalleMedicamento, fijaDetalleMedicamento] = useState(null)
    const [medicamentoSeleccionado, fijaMedicamentoSeleccionado] = useState(null)

    useEffect(() => {
        UpTittle(titulo)
    })

    const handlerCrearReceta = (nom, val) => {
        crearReceta({
            id: recetaId ?? receta?.id,
            body: {
                [nom]: val,
                vendedor: doctor?.id,
                fecha: receta?.fecha ?? adaptarFechaBD(),
                cxp_cxc: 'cobrar',
            }
        })
        .unwrap()
        .then(res => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaRecetaId(id)
                return(id)
            }
            else return recetaId ?? receta?.id
        })
    }

    const handlerAgregarMedicamento = () => {
        console.log(detalleMedicamento)
    }

    const handlerInput = (val, nom) => {
        handlerCrearReceta(nom, val)
        handlerProps(nom, val)
    }

    const handlerSelect = (val, nom) => {
        handlerCrearReceta(nom, val.id)
        handlerProps(nom, val.id, {
            nom_paciente: val.text
        })
    }

    const handlerProps = (nom, val, extra) => {
        UpProps({
            [NOM_FORM_RECETA]: {
                ...receta,
                id: recetaId ?? receta?.id,
                ...extra,
                [nom]: val
            }
        })
    }

    const handlerOnChangeMedicamento = (nom, val, tipo) => {
        let extra = null
        if(tipo === 'select') {
            if(nom === 'producto') fijaMedicamentoSeleccionado(val.item)

            extra = { [`${nom}_texto`]: val.text }
            val = val.id
        }
        else {
            extra = {}
        }

        fijaDetalleMedicamento({
            ...detalleMedicamento,
            ...extra,
            [nom]: val,
            transaccion: recetaId,
        })
    }

    if(isLoading || cargandoMedicamentos) return
    else {
        return (
            <>
                <ModalForm
                    show={mostrarModal}
                    titulo="Generando receta"
                    onClose={() => { fijaMostrarModal(false) }}
                >
                    <LoaderCircle size="medium" />
                </ModalForm>

                <Formulario
                    titulo="Agregar la información de la receta"
                    botones={[
                        { iconLeft: 'fa-plus', texto: 'Agregar', onClick: handlerAgregarMedicamento },
                        { texto: 'Generar receta', onClick: () => { fijaMostrarModal(true) } },
                    ]}
                    isLoading={[lReceta]}
                    isError={[eReceta]}
                    isSuccess={[sReceta]}
                >
                    {
                        (!paciente) ?
                            <Select 
                                nombre="cliente"
                                value={receta?.nom_paciente ?? paciente?.nombre ?? 'Selecciona paciente'}
                                changeFunction={handlerSelect}
                                busqueda={{ placeholder: 'Paciente' }}
                                options={adaptarDatos(pacientes, 'nombre', 'id')}
                                isLoading={lReceta}
                            /> : undefined
                    }
                    <Input 
                        type="textarea"
                        placeholder="Diagnóstico"
                        nombre="tipo"
                        changeFunction={handlerInput}
                        isLoading={lReceta}
                        value={receta?.tipo}
                    />
                    {
                        (paciente) ?
                            <Select 
                                value={`${paciente?.eureka_st_transacciones?.length} receta(s) emitida(s) anteriormente`}
                                disabled={true}
                            /> : undefined
                    }

                    <br />
                    <p><b>Medicamentos</b></p>
                    <SeccionFormMedicamento 
                        medicamentos={medicamentos}
                        onChange={handlerOnChangeMedicamento}
                        detalleMedicamento={detalleMedicamento}
                    />

                    <br />
                    <ListaMedicamentos 
                        medicamentoPrincipal={medicamentoSeleccionado}
                        otrosMedicamentos={[
                            { nombre: 'paracetamol' }
                        ]}
                        permitirEditar={true}
                        onChange={(m, i) => { 
                            console.log(m)
                            console.log(i)
                        }}
                    />
                </Formulario>
            </>
        )
    }
}

export default FormReceta