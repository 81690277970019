import { obtenerLogo } from '../../js/util'
import './SeccionInformacion.css'
import { LazyImage, Button } from 'eureka-design'

const SeccionInformacion = ({
    children,
    usuario,
    botones = [],
    titulo = "",
}) => {
    return (
        <div className="seccion-informacion">
            {usuario && <div className="seccion-informacion__principal">
                <LazyImage
                    src={obtenerLogo}
                    avatar={true}
                    width="100px"
                    height="80px"
                />
                <div className="seccion-informacion__principal__datos">
                    <h1>{titulo}</h1>

                    <p><span>Nombre: </span>{usuario?.nombre}</p>
                    <p><span>Teléfono: </span>{usuario?.telefono}</p>
                    <p><span>Correo eléctronico: </span>{usuario?.correo}</p>
                </div>
            </div>}
            <div className="seccion-informacion__acciones">
                {
                    botones.map((b, i) => {
                        return (
                            <Button
                                key={i}
                                texto={b.texto}
                                iconLeft={b.icon}
                                onClick={b.onClick}
                                select={true}
                            />
                        )
                    })
                }
            </div>
            {children}
        </div>
    )
}

export default SeccionInformacion